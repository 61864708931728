import * as React from 'react'
import type { PageProps } from 'gatsby'
import { graphql, Link } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { FilledLinkToWebField } from '@prismicio/types'
import { Layout } from '../components/Layout'
import { Share } from '../components/Share'

import * as styles from './JobListing.module.scss'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { SEOSocialMeta } from '../components/SEOSocialMeta'
import { checkImageIsSVG } from '../utils/urlUtils'

type JobListingProps = {
  data: Queries.JobListingQuery
  location: {
    state?: {
      jobLocation: string
    }
  }
}

const JobListing = ({ location, data }: JobListingProps) => {
  if (!data || !data.prismicJobListing) {
    return null
  }
  const doc = data.prismicJobListing.data
  const id = data.prismicJobListing.id

  return (
    <Layout>
      <SEOSocialMeta
        facebook={{
          description: doc.facebook_description ?? doc.job_description?.text,
          image: doc.facebook_image?.url,
          title: doc.facebook_title ?? doc.position_title,
        }}
        seo={{
          description: doc.seo_description ?? doc.job_description?.text,
          title: doc.seo_title ?? doc.position_title,
        }}
        twitter={{
          description: doc.twitter_description ?? doc.job_description?.text,
          image: doc.twitter_image?.url,
          title: doc.twitter_title ?? doc.position_title,
        }}
      />
      <div className={styles.container}>
        <div className={styles.content}>
          <Link
            className={styles.back}
            to={`/careers`}
            state={{ jobLocation: location.state?.jobLocation }}
          >
            <i className="icon-back">
              <div>BACK</div>
            </i>
          </Link>
          <div className={styles.title}>{doc.position_title}</div>
          <div className={styles.locationType}>
            {Object.keys(doc.location?.document ?? {}).length !== 0 && (
              <i className="icon-location filter-ash">
                {`${
                  (doc.location?.document as Queries.PrismicLocation).data
                    ?.location_name
                }, ${doc.job_type}}`}
              </i>
            )}
          </div>
          <div className={styles.application}>
            <PrismicLink
              field={doc.application_link as FilledLinkToWebField}
              className={styles.button}
            >
              Click To Apply
            </PrismicLink>
            <div className={styles.share}>
              <Share
                link={`https://gamurs.group/careers/${id}`}
                title={doc.position_title ?? ''}
              />
            </div>
          </div>
          <div className={styles.jobDetails}>
            <PrismicRichText field={doc.job_description?.richText} />
          </div>
          <div className={styles.benefitsTitle}>Benefits</div>
          {doc.job_benefits ? (
            <div className={styles.benefits}>
              {doc.job_benefits.map((entry, index) => {
                if (
                  entry?.benefit?.document &&
                  Object.keys(entry?.benefit?.document).length !== 0
                ) {
                  const benefit = entry.benefit
                    .document as Queries.PrismicJobBenefit
                  return (
                    <div className={styles.oneBenefit}>
                      {checkImageIsSVG(benefit?.data.image?.url) ? (
                        <img
                          src={benefit.data.image?.url ?? undefined}
                          alt={benefit.data.image?.alt ?? ''}
                          className={styles.benefitImage}
                        />
                      ) : (
                        <GatsbyImage
                          image={
                            benefit.data?.image
                              ?.gatsbyImageData as unknown as IGatsbyImageData
                          }
                          aria-hidden={true}
                          alt={benefit.data?.image?.alt ?? ''}
                          imgClassName={styles.benefitImage}
                        />
                      )}
                      <div>{benefit.data?.name}</div>
                    </div>
                  )
                } else {
                  return null
                }
              })}
            </div>
          ) : undefined}
          <PrismicLink
            field={doc.application_link as FilledLinkToWebField}
            className={styles.button}
          >
            Click To Apply
          </PrismicLink>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query JobListing($id: String) {
    prismicJobListing(id: { eq: $id }) {
      id
      data {
        ...JobListingDataSEOSocialMeta
        job_type
        application_link {
          id
          url
          type
          link_type
        }
        job_benefits {
          benefit {
            document {
              ... on PrismicJobBenefit {
                id
                data {
                  name
                  image {
                    gatsbyImageData(placeholder: BLURRED, width: 40)
                    url
                    alt
                  }
                }
              }
            }
          }
        }
        job_description {
          richText
          text
        }
        location {
          document {
            ... on PrismicLocation {
              id
              data {
                location_name
              }
            }
          }
        }
        position_title
      }
    }
  }
`

export default withPrismicPreview(
  JobListing as ({
    location,
    data,
  }: PageProps<Queries.JobListingQuery>) => JSX.Element | null
)
